/* src/assets/styles/theme.css */

.text-color-primary {
    @apply text-primary dark:text-primaryDark transition-colors duration-500 ease-in-out;
}

.hover\:text-color-primary-hover {
    @apply hover:text-primary dark:hover:text-primaryDark transition-colors duration-100 ease-in-out;
}

.bg-color-primary {
    @apply bg-primary dark:bg-primaryDark transition-colors duration-500 ease-in-out;
}

.bg-theme {
    @apply bg-background dark:bg-backgroundDark transition-colors duration-500 ease-in-out;
}

.bg-auth-theme {
    @apply bg-[linear-gradient(241deg,_#F7F7F7_32%,_#9B94C7_214.4%)] dark:bg-[linear-gradient(56deg,_#171717_12.89%,_#9B94C7_244.77%)] transition-colors duration-500 ease-in-out;
}

.bg-surface-theme {
    @apply bg-surface dark:bg-surfaceDark transition-colors duration-500 ease-in-out;
}

.border-theme {
    @apply border-border dark:border-borderDark transition-colors duration-500 ease-in-out;
}

.from-bg-theme {
    @apply from-background dark:from-backgroundDark transition-colors duration-500 ease-in-out;
}

.from-surface-theme {
    @apply from-surface dark:from-surfaceDark transition-colors duration-500 ease-in-out;
}

.text-primary-theme {
    @apply text-textPrimary dark:text-textPrimaryDark transition-colors duration-500 ease-in-out;
}

.text-secondary-theme {
    @apply text-textSecondary dark:text-textSecondaryDark transition-colors duration-500 ease-in-out;
}

.bg-theme-light {
    /*@apply bg-primaryBgColor dark:bg-darkPrimaryBgColor transition-colors duration-500 ease-in-out;*/
    @apply bg-white;
}

.bg-theme-focus {
    @apply bg-border dark:bg-borderDark transition-colors duration-500 ease-in-out;
}

.hover\:bg-theme-hover {
    @apply hover:bg-hover dark:hover:bg-borderDark transition-colors duration-100 ease-in-out;
}

/*.custom-scrollbar {*/
/*    &::-webkit-scrollbar {*/
/*        width: auto;*/
/*    }*/

/*    &::-webkit-scrollbar-track {*/
/*        @apply dark:bg-scrollbarBgColor;*/
/*    }*/

/*    &::-webkit-scrollbar-thumb {*/
/*        @apply dark:bg-scrollbarColor;*/
/*    }*/

/*    &::-webkit-scrollbar-button {*/
/*        @apply dark:bg-scrollbarColor;*/
/*        border: none;*/
/*    }*/

/*    &::-webkit-scrollbar-thumb:hover {*/
/*        @apply dark:bg-scrollbarColorHover;*/
/*    }*/
/*}*/
