@import './theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    overscroll-behavior: none;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none; /* Pour Internet Explorer et Edge */
    scrollbar-width: none; /* Pour Firefox */
}

/*REVOIR POUR QUE LA SCROLLBAR SOIT EN DARK LORSQUE LE THEME EST EN DARK !*/
@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}

.test {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 800px;
    height: 400px;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraBoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
